<template>
    <div class="page">
        <page-header></page-header>
        <div class="page-peiple">
            <div class="user-xq">
                <div class="user-xq-top">
                    <div>
                        <img :src="$isApi.http + userForm.header" alt="">
                    </div>
                    <div class="user-xq-content">
                        <div>
                            <span>{{userForm.nickName}}</span>
                            <i v-if="userForm.sex == 'women'" class="iconfont icon-xingbienv1"></i>
                            <i v-else style="background:#0063B1" class="iconfont icon-xingbienan1"></i>
                        </div>
                        <div>
                            <div v-if="userForm.height">{{userForm.height}}CM</div>
                            <div v-if="userForm.weight">{{userForm.weight}}KG</div>
                            <div v-if="userForm.age">{{userForm.age}}</div>
                            <div v-if="userForm.area">{{userForm.area}}</div>
                        </div>
                        <div>
                            <div v-if="userForm.income">{{userForm.education}}</div>
                            <div v-if="userForm.occupation">{{userForm.occupation}}</div>
                            <div v-if="userForm.income">{{$t('sendUser.income')}}：{{userForm.income}}</div>
                            <div v-if="userForm.marriageStatus">{{userForm.marriageStatus}}</div>
                        </div>
                        <div>
                            <div>{{$t('sendUser.loveSay')}}：</div>
                            <div v-if="userForm.signature">{{userForm.signature}}</div>
                        </div>
                        <div>
                            <div style="background:#FA5E94" @click="isChat">{{$t('userXQ.text_01')}}</div>
                            <div style="background:#5A81CD" @click="goGift">{{$t('userXQ.text_02')}}</div>
                            <div v-if="isMyLike" style="background:#FD463E" @click="isLike('yes')">{{$t('userXQ.text_03')}}</div>
                            <div v-else style="background:#CCCCCC" @click="isLike('no')">{{$t('userXQ.text_03')}}</div>
                        </div>
                    </div>
                </div>
                <div class="user-xq-bottom">
                    <div class="user-xq-girl">
                        <div>
                            <i class="iconfont icon-picture"></i>
                            <span>{{$t('userXQ.title_01')}}</span>
                        </div>
                        <div v-if="photoList.length > 0">
                            <div v-for="(item,index) in photoList" :key="index">
                                <el-image 
                                    fit="contain"
                                    class="imgs-c"
                                    :src="item.fileUrl"
                                    :preview-src-list="imgs">
                                </el-image>
                                <div>{{$isPulic.timestampToTime(item.createDate)}}</div>
                            </div>
                        </div>
                        <div v-else style="text-align:center;line-height:100px;color:#999">{{$t('sendUser.title_1')}}</div>
                    </div>
                    <div class="user-xq-dongtai">
                        <div>
                            <i class="iconfont icon-dingdan"></i>
                            <span>{{$t('userXQ.title_02')}}</span>
                        </div>
                        <div v-if="stateList.length > 0">
                            <div class="user-xq-dongtai-item" v-for="(item,index) in stateList" :key="index">
                                <div>{{item.content}}</div>
                                <div v-if="item.fileUrl instanceof Array">
                                    <el-image 
                                        class="user-xq-dongtai-item-i" 
                                        v-for="(img,index) in item.fileUrl" 
                                        :key="index" 
                                        :src="img" 
                                        :preview-src-list="item.fileUrl">
                                    </el-image>
                                </div>
                                <div v-else-if="item.fileUrl.IsPicture('.jpg') || item.fileUrl.IsPicture('.png') || item.fileUrl.IsPicture('.gif') || item.fileUrl.IsPicture('.jpeg')">
                                    <el-image 
                                        fit="contain"
                                        class="user-xq-dongtai-item-i"
                                        :src="item.fileUrl"
                                        :preview-src-list="[item.fileUrl]">
                                    </el-image>
                                </div>
                                <div v-else-if="item.type == 'video'">
                                    <div @click="videoChange" v-if="sendForm.level == 0" class="iconfont icon-icon_play video-icon"></div>
                                    <video 
                                        v-else
                                        :src="$isApi.http + item.fileUrl"
                                        class="video-avatar"
                                        controls="controls" />
                                </div>
<!--                                <div>{{$isPulic.timestampToTime(item.createDate)}}</div>-->
                            </div>
                        </div>
                        <div v-else style="text-align:center;line-height:100px;color:#999">{{$t('userXQ.text_04')}}</div>
                    </div>
                </div>
            </div>
            <div class="page-peiple-right">
                <love-gift></love-gift>
                <love-wall></love-wall>
            </div>
        </div>
        <page-fixed></page-fixed>
        <page-bottom></page-bottom>
        <transition name="el-zoom-in-top">
            <user-form-list v-if="$store.state.sayShow"></user-form-list>
        </transition>
        <transition name="el-zoom-in-top">
            <gift-list v-if="$store.state.giftShow"></gift-list>
        </transition>
        <transition name="el-zoom-in-top">
            <pay v-if="$store.state.payShow"></pay>
        </transition>
        <transition name="el-zoom-in-top">
            <upgrade v-if="$store.state.upgradeShow"></upgrade>
        </transition>
    </div>
</template>

<script>
import PageHeader from '../../components/pageHeader.vue'
import PageBottom from '../../components/pageBottom.vue'
import PageFixed from '../../components/pageFixed.vue'
import LoveGift from '../../components/home/loveGift.vue'
import LoveWall from '../../components/home/loveWall.vue'
import userFormList from '../../components/say.vue'
import GiftList from '../../components/gift.vue'
import Pay from '../../components/pay.vue'
import Upgrade from '../../components/upgrade.vue'
export default {
    components: {PageHeader,PageBottom,PageFixed,LoveWall,userFormList,LoveGift,GiftList,Pay,Upgrade},
    data(){
        return{
            userForm: {},
            stateList: [],
            photoList: [],
            imgs: [],
            sendForm: {},
            isMyLike: false
        }
    },
    created(){
        // 获取用户资料
        this.sendForm = JSON.parse(sessionStorage.getItem('sendForm'))
        this.$isAxios.axiosGet(this.$isApi.showUserInfo,{userId:sessionStorage.userId},(user)=>{
            if(user.data.result){
                this.userForm = user.data.result
                this.$isAxios.axiosGet(this.$isApi.showAll,{userId: user.data.result.userId},(res)=>{
                    if(res.data.result){
                        var imgs = []
                        res.data.result.forEach(item=>{
                            imgs.push(item.fileUrl)
                        })
                        this.imgs = imgs
                        this.photoList = res.data.result
                    }
                })
                this.$isAxios.axiosGet(this.$isApi.showUserDynamics,{userId:user.data.result.userId},(res)=>{
                    if(res.data.result != null){
                        res.data.result.forEach( item => {
                            if(item.fileUrl){
                                if(item.fileUrl.indexOf(",") != -1){
                                    item.fileUrl = item.fileUrl.split( ',' )
                                }
                            }
                        })
                        this.total = res.data.total
                        this.stateList = res.data.result
                    }
                })
                this.$isAxios.axiosGet(this.$isApi.findUserLike,{userId:this.sendForm.userId,beLikedId:user.data.result.userId},(res)=>{
                    if(res.data.message == 'success'){
                        if(res.data.result){
                            this.isMyLike = true
                        }else{
                            this.isMyLike = false
                        }
                    }
                })
            }
        })
    },
    methods: {
        videoChange(){
            this.$confirm(this.$i18n.t('login.shenVipState1'), this.$i18n.t('giftComponents.reminder'), {
                confirmButtonText: this.$i18n.t('userLeft.success'),
                cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                type: 'warning'
            }).then(() => {
                this.$store.state.upgradeShow = true
            })
        },
        // 聊天
        isChat(){
            this.$isChat.newChat(this,this.userForm)
        },
        // 送礼
        goGift(){
            if(this.sendForm.level != 0){
                this.$store.state.setGiftUserForm = this.userForm
                this.$store.state.giftShow = true
            }else{
                this.$confirm(this.$i18n.t('login.shenVipState2'), this.$i18n.t('giftComponents.reminder'), {
                    confirmButtonText: this.$i18n.t('userLeft.success'),
                    cancelButtonText: this.$i18n.t('giftComponents.cancel'),
                    type: 'warning'
                }).then(() => {
                    this.$store.state.sayShow = false
                    this.$nextTick(()=>{
                        this.$store.state.upgradeShow = true
                    })
                })
            }
        },
        // 喜欢
        isLike(type){
            if(type == 'no'){
                var json = {
                    userId: this.sendForm.userId,
                    beLikedId: this.userForm.userId,
                    isRead: false
                }
                this.$isAxios.axiosPost(this.$isApi.addMyLike,json,(res)=>{
                    if(res.data.message == 'success'){
                        this.isMyLike = true
                        this.$message({
                            message: this.$i18n.t('grilList.addSuccess'),
                            type: 'success'
                        })
                    }
                })
            }else{
                this.$message(this.$i18n.t('grilList.addGril'))
            }
        }
    }
}
</script>

<style scoped>
.video-icon{
    width: 200px;
    height: 140px;
    background: #000;
    text-align: center;
    line-height: 140px;
    color: #fff;
    font-size: 24px;
    margin-top: 14px;
    cursor: pointer;
}
.user-xq{
    width: 866px;
    flex-grow: 0;
    height: max-content;
}
.user-xq-top{
    height: max-content;
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, .1);
}
.user-xq-top>div:nth-child(1){
    width: 220px;
    height: max-content;
    flex-grow: 0;
}
.user-xq-top>div:nth-child(1)>img{
    width: 100%;
    height: 280px;
    background: #000;
    object-fit: cover
}
.user-xq-content{
    width: calc( 100% - 240px );
    height: 300px;
}
.user-xq-content>div:nth-child(1){
    display: flex;
    flex-direction: row;
    align-items: center;
}
.user-xq-content>div:nth-child(1)>span{
    font-size: 22px;
    color: #333;
    display: block
}
.user-xq-content>div:nth-child(1)>i{
    font-size: 22px;
    color: #fff;
    display: block;
    margin-left: 10px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    background: #FD78C2;
}
.user-xq-content>div:nth-child(2),
.user-xq-content>div:nth-child(3),
.user-xq-content>div:nth-child(5){
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 16px;
    cursor: pointer;
}
.user-xq-content>div:nth-child(2)>div{
    height: 28px;
    width: max-content;
    padding: 0 20px;
    border-radius: 14px;
    line-height: 28px;
    background: #E3F4FE;
    font-size: 14px;
    color: #666;
    margin-right: 10px;
}
.user-xq-content>div:nth-child(3)>div{
    height: 14px;
    line-height: 14px;
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #999;
    color: #666;
    margin-top: 6px;
}
.user-xq-content>div:nth-child(3)>div:first-child{
    margin-left: 2px;
}
.user-xq-content>div:nth-child(3)>div:last-child{
    border: none
}
.user-xq-content>div:nth-child(4){
    margin-top: 20px;
    background: #FFFAF0;
    padding: 14px 16px;
    border: 1px solid #FFE8B4;
    border-radius: 4px;
    margin-left: 4px;
}
.user-xq-content>div:nth-child(4)>div:nth-child(1){
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 6px;
}
.user-xq-content>div:nth-child(4)>div:nth-child(2){
    font-size: 13px;
    color: #666;
    margin-bottom: 20px;
}
.user-xq-content>div:nth-child(5)>div{
    width: max-content;
    height: 40px;
    padding: 0 24px;
    line-height: 40px;
    color: #fff;
    margin-right: 8px;
    border-radius: 3px;
    font-size: 14px;
}
.user-xq-content>div:nth-child(5)>div:first-child{
    margin-left: 4px;
}

.user-xq-bottom{
    background: #fff;
    height: max-content;
    box-shadow: 3px 3px 6px rgba(0, 0, 0, .1);
    margin-top: 20px;
    padding-top: 20px;
}
.user-xq-girl{
    display: flex;
    justify-content: space-between;
    height: max-content;
    padding: 20px
}
.user-xq-dongtai{
    display: flex;
    justify-content: space-between;
    height: max-content;
    padding: 20px
}
.user-xq-girl>div:nth-child(1)
.user-xq-dongtai>div:nth-child(1){
    width: 100px;
    height: max-content;
    flex-grow: 0;
    padding-top: 6px;
}
.user-xq-girl>div:nth-child(1)>i,
.user-xq-dongtai>div:nth-child(1)>i{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    text-align: center;
    line-height: 60px;
    background: #FA5E94;
    font-size: 30px;
    color: #fff;
}
.user-xq-girl>div:nth-child(1)>span,
.user-xq-dongtai>div:nth-child(1)>span{
    display: block;
    margin: 0 auto;
    font-size: 14px;
    color: #666;
    width: max-content;
    margin-top: 10px;
}
.user-xq-girl>div:nth-child(2){
    width: calc( 100% - 140px );
    flex-grow: 0;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px dashed #ccc
}
.imgs-c{
    width: 130px;
    height: 130px;
    background: #000
}
.user-xq-girl>div:nth-child(2)>div{
    width: 130px;
    height: max-content;
    margin: 0 10px 10px 0;
    padding-bottom: 20px
}
.user-xq-girl>div:nth-child(2)>div>div{
    font-size: 12px;
    margin-top: 4px
}
.user-xq-dongtai>div:nth-child(2){
    width: calc( 100% - 140px );
    flex-grow: 0;
    display: flex;
    flex-wrap: wrap;
}
.user-xq-dongtai-item{
    width: 100%;
    height: max-content;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed #eee
}
.user-xq-dongtai-item:last-child{
    border: none
}
.user-xq-dongtai-item>div:nth-child(1){
    font-size: 16px;
    color: #333;
}
.user-xq-dongtai-item>div:nth-child(2){
    height: max-content;
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0 4px 0
}
.user-xq-dongtai-item-i{
    width: 80px;
    height: 80px;
    object-fit: cover;
    margin: 0 10px 10px 0;
    background: #000
}
.user-xq-dongtai-item>div:nth-child(3){
    font-size: 13px;
    color: #999;
}
</style>